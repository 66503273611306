import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

export const PinManagementServices = createApi({
  reducerPath: 'pincodeManagementServices',
  baseQuery: baseQuery,
  tagTypes: ['PinCodeManagement'],
  endpoints: (builder) => ({
    // 👉 Get All Route
    getAllPinCodes: builder.query({
      query: () => {
        return {
          url: '/serviceable-pincodes',
        };
      },
    }),

    // 👉 Get All Roles
    getAllPincodesList: builder.query({
      query: (arg) => {
        const { current, pageSize, q } = arg;

        return {
          url: '/serviceable-pincodes',
          params: { page: current, per_page: pageSize, 'filter[query]': q },
        };
      },
      providesTags: ['RoleManagement'],
    }),

    // 👉 Get Role Details
    getRoleDetails: builder.query({
      query: (id) => {
        return {
          url: `/roles/edit/${id}`,
        };
      },
    }),

    // 👉 Create New Role
    addPincode: builder.mutation({
      query: (payload) => {
        return {
          url: `/serviceable-pincodes/store`,
          method: 'POST',
          body: payload,
        };
      },
    }),

    // 👉 Update Role
    updatePincode: builder.mutation({
      query: (payload) => {
        return {
          url: `/serviceable-pincodes/update`,
          method: 'POST',
          body: payload,
        };
      },
    }),

    // 👉 Update Status
    updateStatus: builder.mutation({
      query: (payload) => {
        return {
          url: `/roles/update-role-status`,
          method: 'POST',
          body: payload,
        };
      },
    }),

    // 👉 Get Active Role
    getActiveRoles: builder.query({
      query: () => {
        return {
          url: `/roles/get-active-roles`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetAllPinCodesQuery,
  useGetAllPincodesListQuery,
  useGetRoleDetailsQuery,
  useAddPincodeMutation,
  useUpdatePincodeMutation,
  useUpdateStatusMutation,
  useGetActiveRolesQuery,
} = PinManagementServices;
