import React from 'react';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/login')),
  },
  {
    key: 'terms-and-conditions',
    path: `${AUTH_PREFIX_PATH}/terms-and-conditions`,
    component: React.lazy(() => import('views/termsAndConditions')),
  }
];

export const protectedRoutes = [
  // 👉 Dashboard Routes
  {
    key: 'dashboard.default',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import('views/dashboard')),
  },

  // 👉 Customers Routes
  {
    key: 'customers.index',
    path: `${APP_PREFIX_PATH}/customers`,
    component: React.lazy(() => import('views/customers')),
  },
  {
    key: 'customers.show',
    path: `${APP_PREFIX_PATH}/customers/:id/show`,
    component: React.lazy(() => import('views/customers/show')),
  },
  {
    key: 'customers.create',
    path: `${APP_PREFIX_PATH}/customers/create`,
    component: React.lazy(() => import('views/customers/create')),
  },

  // 👉 Category Routes
  {
    key: 'categories.index',
    path: `${APP_PREFIX_PATH}/categories`,
    component: React.lazy(() => import('views/categories')),
  },
  {
    key: 'categories.show',
    path: `${APP_PREFIX_PATH}/categories/:id/show`,
    component: React.lazy(() => import('views/categories/show')),
  },
  {
    key: 'categories.create',
    path: `${APP_PREFIX_PATH}/categories/create`,
    component: React.lazy(() => import('views/categories/create')),
  },

  {
    key: 'categories.edit',
    path: `${APP_PREFIX_PATH}/categories/:id/edit`,
    component: React.lazy(() => import('views/categories/edit')),
  },

  {
    key: 'categories.slider',
    path: `${APP_PREFIX_PATH}/categories/:id/slider`,
    component: React.lazy(() => import('views/categories/slider')),
  },

  // 👉 Products Routes
  {
    key: 'products.index',
    path: `${APP_PREFIX_PATH}/products`,
    component: React.lazy(() => import('views/products')),
  },

  {
    key: 'products.create',
    path: `${APP_PREFIX_PATH}/products/create`,
    component: React.lazy(() => import('views/products/create')),
  },

  {
    key: 'products.edit',
    path: `${APP_PREFIX_PATH}/products/:id/edit`,
    component: React.lazy(() => import('views/products/edit')),
  },

  // 👉 Orders Routes
  {
    key: 'orders.index',
    path: `${APP_PREFIX_PATH}/orders`,
    component: React.lazy(() => import('views/orders')),
  },

  {
    key: 'orders.show',
    path: `${APP_PREFIX_PATH}/orders/:id/show`,
    component: React.lazy(() => import('views/orders/show/index')),
  },

  // 👉 Subscriptions Routes
  {
    key: 'subscriptions.index',
    path: `${APP_PREFIX_PATH}/subscriptions`,
    component: React.lazy(() => import('views/subscriptions')),
  },

  {
    key: 'subscriptions.show',
    path: `${APP_PREFIX_PATH}/subscriptions/:id/show`,
    component: React.lazy(() => import('views/subscriptions/show')),
  },

  // 👉 Notifications Routes
  {
    key: 'notifications.index',
    path: `${APP_PREFIX_PATH}/notifications`,
    component: React.lazy(() => import('views/notifications')),
  },

  // 👉 Deliveries Routes
  {
    key: 'deliveries.index',
    path: `${APP_PREFIX_PATH}/deliveries`,
    component: React.lazy(() => import('views/deliveries')),
  },

  // 👉 Guest Deliveries Routes
  {
    key: 'guest-deliveries.index',
    path: `${APP_PREFIX_PATH}/guest-deliveries`,
    component: React.lazy(() => import('views/guest-deliveries')),
  },

  // 👉 Sliders Routes
  {
    key: 'sliders.index',
    path: `${APP_PREFIX_PATH}/sliders`,
    component: React.lazy(() => import('views/sliders')),
  },

  // 👉 Carriers Routes
  {
    key: 'carriers.index',
    path: `${APP_PREFIX_PATH}/carriers`,
    component: React.lazy(() => import('views/carriers')),
  },

  // 👉 Carriers Routes
  {
    key: 'carriers.index',
    path: `${APP_PREFIX_PATH}/bottles`,
    component: React.lazy(() => import('views/bottles')),
  },
  {
    key: 'carriers.show',
    path: `${APP_PREFIX_PATH}/carriers/:id/show`,
    component: React.lazy(() => import('views/carriers/show/index')),
  },

  // 👉 Staffs Routes
  {
    key: 'staffs.index',
    path: `${APP_PREFIX_PATH}/staffs`,
    component: React.lazy(() => import('views/staffs')),
  },
  {
    key: 'staffs.show',
    path: `${APP_PREFIX_PATH}/staffs/:id/show`,
    component: React.lazy(() => import('views/staffs/show/index')),
  },

  // 👉 Wallets Routes
  {
    key: 'wallets.index',
    path: `${APP_PREFIX_PATH}/wallets`,
    component: React.lazy(() => import('views/wallets')),
  },
  {
    key: 'wallets.show',
    path: `${APP_PREFIX_PATH}/wallets/:id/show`,
    component: React.lazy(() => import('views/wallets/show/index')),
  },

  // 👉 Reports Routes
  {
    key: 'reports.index',
    path: `${APP_PREFIX_PATH}/reports`,
    component: React.lazy(() => import('views/reports')),
  },

  // 👉 Complain Routes
  {
    key: 'complain.index',
    path: `${APP_PREFIX_PATH}/complain`,
    component: React.lazy(() => import('views/complain')),
  },

  // 👉 Complain Routes
  {
    key: 'popup.index',
    path: `${APP_PREFIX_PATH}/popup`,
    component: React.lazy(() => import('views/popup')),
  },

  // 👉 Banner Routes
  {
    key: 'banner.index',
    path: `${APP_PREFIX_PATH}/banners`,
    component: React.lazy(() => import('views/banners')),
  },

  {
    key: 'banner.edit',
    path: `${APP_PREFIX_PATH}/banners/:id/edit`,
    component: React.lazy(() => import('views/banners/edit')),
  },

  {
    key: 'banner.create',
    path: `${APP_PREFIX_PATH}/banners/create`,
    component: React.lazy(() => import('views/banners/create')),
  },

  // 👉 Subscription Offer Routes

  {
    key: 'subscription-offer.index',
    path: `${APP_PREFIX_PATH}/subscription`,
    component: React.lazy(() => import('views/subscription-offer')),
  },
  {
    key: 'subscription-offer.edit',
    path: `${APP_PREFIX_PATH}/subscription/:id/edit`,
    component: React.lazy(() => import('views/subscription-offer/edit')),
  },
  {
    key: 'subscription-offer.create',
    path: `${APP_PREFIX_PATH}/subscription/create`,
    component: React.lazy(() => import('views/subscription-offer/create')),
  },

  // 👉 Popup Offer Routes

  {
    key: 'popup-offer.index',
    path: `${APP_PREFIX_PATH}/popupoffer`,
    component: React.lazy(() => import('views/pop-up-offer')),
  },
  {
    key: 'popup-offer.edit',
    path: `${APP_PREFIX_PATH}/popupoffer/:id/edit`,
    component: React.lazy(() => import('views/pop-up-offer/edit')),
  },
  {
    key: 'popup-offer.create',
    path: `${APP_PREFIX_PATH}/popupoffer/create`,
    component: React.lazy(() => import('views/pop-up-offer/create')),
  },

  // 👉 Role Management Routes

  {
    key: 'role-management.index',
    path: `${APP_PREFIX_PATH}/roles`,
    component: React.lazy(() => import('views/roles')),
  },
  {
    key: 'role-management.create',
    path: `${APP_PREFIX_PATH}/roles/create`,
    component: React.lazy(() => import('views/roles/create')),
  },
  {
    key: 'role-management.edit',
    path: `${APP_PREFIX_PATH}/roles/edit/:id`,
    component: React.lazy(() => import('views/roles/edit')),
  },



  // 👉 Pin code management routes

  {
    key: 'pincode-management.index',
    path: `${APP_PREFIX_PATH}/pincodes`,
    component: React.lazy(() => import('views/pin-code-masters')),
  },

  // 👉 Coupons Routes
  {
    key: 'coupons.index',
    path: `${APP_PREFIX_PATH}/coupons`,
    component: React.lazy(() => import('views/coupons')),
  },
  {
    key: 'coupons.show',
    path: `${APP_PREFIX_PATH}/coupons/:id/show`,
    component: React.lazy(() => import('views/coupons/show')),
  },
  {
    key: 'coupons.create',
    path: `${APP_PREFIX_PATH}/coupons/create`,
    component: React.lazy(() => import('views/coupons/create')),
  },

  {
    key: 'coupons.edit',
    path: `${APP_PREFIX_PATH}/coupons/:id/edit`,
    component: React.lazy(() => import('views/coupons/edit')),
  },



  // 👉 Rewards Routes
  {
    key: 'rewards.index',
    path: `${APP_PREFIX_PATH}/rewards`,
    component: React.lazy(() => import('views/rewards')),
  },

  // 👉 drop Point Routes
  {
    key: 'dropPoint.index',
    path: `${APP_PREFIX_PATH}/dropPoint`,
    component: React.lazy(() => import('views/dropPoint')),
  },
];
